import {FETCH_WORK_ORDERS_SUCCESS, PATCH_WORK_ORDER_SEND} from "../actions/workorder.action";

const initState = {
    workOrders: []
}

const workOrdersReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_WORK_ORDERS_SUCCESS:
            return {
                ...state,
                workOrders: action.payload
            }
        case PATCH_WORK_ORDER_SEND:
            return {
                workOrders: action.meta.offline.effect.meta.workOrders
            }
        default:
            return state
    }
}

export default workOrdersReducer