import getAuthHeader from "../../authentication/auth.header";
import {SERVER_URL} from "./const.action";

export const FETCH_OBJECTS_SUCCESS = 'FETCH_OBJECTS_SUCCESS';
export const PATCH_OBJECT_SEND = 'PATCH_OBJECT_SEND';
export const PATCH_OBJECT_SEND_COMMIT = 'PATCH_OBJECT_SEND_COMMIT';
export const PATCH_OBJECT_SEND_ROLLBACK = 'PATCH_OBJECT_SEND_ROLLBACK';
export const POST_OBJECT_COMMIT = 'POST_OBJECT_COMMIT';
export const POST_OBJECT_ROLLBACK = 'POST_OBJECT_ROLLBACK';
export const POST_OBJECT_SEND = 'POST_OBJECT_SEND';

export const fetchObjectsSuccess = objects => {
    return {
        type: FETCH_OBJECTS_SUCCESS,
        payload: objects
    }
}

export const postObject = (objectToPost, objects) => {
    return {
        type: POST_OBJECT_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/objects/',
                    method: 'POST',
                    headers: getAuthHeader(),
                    json: objectToPost,
                    meta: {objects}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: POST_OBJECT_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: POST_OBJECT_ROLLBACK
                }
            }
        }
    }
}

export const patchObject = (objectId, objectToPatch, objects) => {
    return {
        type: PATCH_OBJECT_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/objects/' + objectId,
                    method: 'PATCH',
                    headers: getAuthHeader(),
                    json: objectToPatch,
                    meta: {objects}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: PATCH_OBJECT_SEND_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: PATCH_OBJECT_SEND_ROLLBACK
                }
            }
        }
    }
}
