import getAuthHeader from "../../authentication/auth.header";
import {SERVER_URL} from "./const.action";

export const FETCH_MAINTENANCES_SUCCESS = 'FETCH_MAINTENANCES_SUCCESS';
export const PATCH_MAINTENANCES_SEND = 'PATCH_MAINTENANCES_SEND';
export const PATCH_MAINTENANCES_SEND_COMMIT = 'PATCH_MAINTENANCES_SEND_COMMIT';
export const PATCH_MAINTENANCES_SEND_ROLLBACK = 'PATCH_MAINTENANCES_SEND_ROLLBACK';
export const POST_MAINTENANCE_SEND = 'POST_MAINTENANCE_SEND';
export const POST_MAINTENANCE_COMMIT = 'POST_MAINTENANCE_COMMIT';
export const POST_MAINTENANCE_ROLLBACK = 'POST_MAINTENANCE_ROLLBACK';

export const fetchMaintenancesSuccess = maintenances => {
    return {
        type: FETCH_MAINTENANCES_SUCCESS,
        payload: maintenances
    }
}

export const postMaintenance = (maintenanceToPost, maintenances) => {
    return {
        type: POST_MAINTENANCE_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/maintenances/',
                    method: 'POST',
                    headers: getAuthHeader(),
                    json: maintenanceToPost,
                    meta: {maintenances}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: POST_MAINTENANCE_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: POST_MAINTENANCE_ROLLBACK
                }
            }
        }
    }
}

export const patchMaintenance = (maintenanceId, objectToPatch, maintenances) => {
    return {
        type: PATCH_MAINTENANCES_SEND,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: SERVER_URL + '/api/maintenances/' + maintenanceId,
                    method: 'PATCH',
                    headers: getAuthHeader(),
                    json: objectToPatch,
                    meta: {maintenances}
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: PATCH_MAINTENANCES_SEND_COMMIT
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: PATCH_MAINTENANCES_SEND_ROLLBACK
                }
            }
        }
    }
}
